import {fetchRestService} from "./foxtrot_rest_service";

class UserService {

  constructor(foxtrotRestService) {
    this._foxtrotRestService = foxtrotRestService;
  }

  getSelf = () => {
    const promise = this._foxtrotRestService.get(`/api/v1/user/current`, {});
    return this._foxtrotRestService.extractResponsePromise(promise).then((data) => data.user);
  };

  logout = () => {
    const promise = this._foxtrotRestService.post(`/api/v1/user/logout`);
    return this._foxtrotRestService.extractResponsePromise(promise);
  };

  getClientFingerprint = () => {
    const promise = this._foxtrotRestService.get('/api/v1/private/client_fingerprint');
    return this._foxtrotRestService.extractResponsePromise(promise).then((data) => data.client_fingerprint);
  };

  getUser = (userId) => {
    const promise = this._foxtrotRestService.get(`/api/v1/user/${userId}`);
    return this._foxtrotRestService.extractResponsePromise(promise).then((data) => data.user);
  };

  getAllUsers = (dcId) => {
    const promise = this._foxtrotRestService.get('/api/v1/users', {
      headers: {
        'Foxtrot-DC-ID': dcId
      }
    });
    return this._foxtrotRestService.extractResponsePromise(promise).then((data) => data.users);
  };

  deleteUser = (dcId, userId) => {
    const promise = this._foxtrotRestService.delete(`/api/v1/user/${userId}`, {
      headers: {
        'Foxtrot-DC-ID': dcId
      }
    });
    return this._foxtrotRestService.extractResponsePromise(promise);
  };

  setupPassword = (token, password, name, phone) => {
    const promise = this._foxtrotRestService.patch(`/api/v1/user/setup_password/${token}`, {
      password: password,
      name: name,
      phone: phone
    });
    return this._foxtrotRestService.extractResponsePromise(promise);
  };

  updateName = (dcId, userId, name) => {
    const promise = this._foxtrotRestService.patch(`/api/v1/user/${userId}/name`, {
      name: name,
      user_id: userId
    },{
      headers: { "Foxtrot-DC-ID": dcId }
    });
    return this._foxtrotRestService.extractResponsePromise(promise).then(data => data.name);
  };

  updatePhone = (dcId, userId, phone) => {
    const promise = this._foxtrotRestService.patch(`/api/v1/user/${userId}/phone`, {
      phone: phone,
      user_id: userId
    }, {
      headers: { "Foxtrot-DC-ID": dcId }
    });
    return this._foxtrotRestService.extractResponsePromise(promise).then(data => data.phone);
  };

  updateUserPermissions = (dcId, userId, permissions) => {
    const promise = this._foxtrotRestService.patch(`/api/v1/user/permissions`, {
      permissions: permissions,
      user_id: userId
    }, {
      headers: { "Foxtrot-DC-ID": dcId }
    });
    return this._foxtrotRestService.extractResponsePromise(promise).then(data => data.permissions);
  };

  usersAlreadyExist = (dcId, usernames) => {
    const promise = this._foxtrotRestService.get(`/api/v1/user/users_already_exist`,
      {
        params: { usernames: usernames },
        paramsSerializer: params => { // axios default array encoding doesn't work as flask expects
          return params.usernames.map(username => "usernames=" + username).join("&")
        },
        headers: { "Foxtrot-DC-ID": dcId},
      }
    );
    return this._foxtrotRestService.extractResponsePromise(promise).then(data => data.duplicate_users);
  };

  createUsersWithPermissionsAndDriverGroups = (dcId, users, permissions, driver_group_ids) => {
    const promise = this._foxtrotRestService.post(`/api/v1/users`, {
      users: users,
      permissions: permissions,
      driver_group_ids: driver_group_ids,
    }, {
      headers: { "Foxtrot-DC-ID": dcId}
    });
    return this._foxtrotRestService.extractResponsePromise(promise)
  }

}

export const userService = new UserService(fetchRestService);
