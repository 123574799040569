import React from "react";

import { PushNotificationManager } from "./PushNotificationManager";

interface PushNotificationManagerContextTypes {
  pushNotificationManager?: PushNotificationManager;
}

const PushNotificationManagerContext = React.createContext<PushNotificationManagerContextTypes>({
  pushNotificationManager: undefined
});

export { PushNotificationManagerContext };
