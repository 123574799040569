import React from "react";

export enum ApplicationType {
  Internal = "INTERNAL",
  External = "EXTERNAL"
}

interface ApplicationTypeContextTypes {
  applicationType: ApplicationType;
}

const ApplicationTypeContext = React.createContext<ApplicationTypeContextTypes>({
  applicationType: ApplicationType.External
});

export { ApplicationTypeContext };
