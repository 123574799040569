import React from "react";
import { CurrentUser } from "../../../common/models/current_user";

interface CurrentUserContextTypes {
  currentUser?: CurrentUser;
  setCurrentUser(user?: CurrentUser): void;
}

const CurrentUserContext = React.createContext<CurrentUserContextTypes>({
  currentUser: undefined,
  setCurrentUser: () => {}
});

export { CurrentUserContext };
