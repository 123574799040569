import _ from "lodash";


const USER_PERMISSION = {
  // There's some duplication in UserRole.tsx. Please make sure you update both.
  USER: "USER",
  FOXTROT_ENGINEERING: "FOXTROT_ENGINEERING",
  FOXTROT: "FOXTROT",
  ANALYTICS_FILE_DOWNLOADS: "ANALYTICS_FILE_DOWNLOADS",
  CUSTOMERS_MANAGER: "CUSTOMERS_MANAGER",
  DRIVER_PERFORMANCE_DASHBOARD: "DRIVER_PERFORMANCE_DASHBOARD",
  FLEET_PERFORMANCE_DASHBOARD: "FLEET_PERFORMANCE_DASHBOARD",
  DC_ADMIN: "DC_ADMIN",
  IMAGE_VIEWER: "IMAGE_VIEWER",
  REALTIME_DASHBOARD: "REALTIME_DASHBOARD",
  ROUTE_ANALYZER: "ROUTE_ANALYZER",
  ROUTE_LIST: "ROUTE_LIST",
  ROUTE_VIEWER: "ROUTE_VIEWER",
  ROUTE_IMPORTER: "ROUTE_IMPORTER",
  ALERTS: "ALERTS",
  MERCHANDISER: "MERCHANDISER",
  CUSTOMER_RATING_ANALYST: "CUSTOMER_RATING_ANALYST"
};


class User {

  constructor(user) {
    const ignored = [];
    _.forOwn(user, (v, k) => {
      if (!_.includes(ignored, k)) {
        this[k] = v;
      }
    });
    this.roles = _.uniq(this.roles).sort();
  }

  getId = () => {
    return this.id.toString();
  };

  getName = () => {
    return this.name;
  };

  getPhone = () => {
    return this.phone;
  };

  getUsername = () => {
    return this.username;
  };

  hasPermission = (userPermission) => {
    return this.roles.includes(userPermission);
  }

}

export {
  User,
  USER_PERMISSION,
}
