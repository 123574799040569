import { lazy } from "react";

type ComponentImportType = () => Promise<any>;

const sessionKey = "lazyWithRetry";

export const lazyWithRetry = (componentImport: ComponentImportType) => {
  return lazy(async () => {
    const hasRefreshed = globalThis.sessionStorage.getItem(sessionKey) || "false";

    try {
      globalThis.sessionStorage.setItem(sessionKey, "false");
      return await componentImport();
    } catch (error) {
      if (hasRefreshed === "false") {
        globalThis.sessionStorage.setItem(sessionKey, "true");
        globalThis.location.reload();
      }

      if (hasRefreshed === "true") throw new Error("chunkLoadError");
    }
    return await componentImport();
  });
};

export default lazyWithRetry;