import {fetchRestService} from "./foxtrot_rest_service";

class DeliveryService {

  constructor(foxtrotRestService) {
    this._foxtrotRestService = foxtrotRestService;
  }

  authorizeReattempt = (driverId, attempts) => {
    const promise = this._foxtrotRestService.post('/api/v2/delivery/attempts', {
      driver_id: driverId,
      attempts: attempts
    });
    return this._foxtrotRestService.extractResponsePromise(promise).then((data) => data.attempts);
  };

  getPendingManagementActionCount = (dcId) => {
    const promise = this._foxtrotRestService.get('/api/v1/delivery/management/pending/count', {
      headers: {'Foxtrot-DC-ID': dcId}
    });
    return this._foxtrotRestService.extractResponsePromise(promise).then(data => data.count);
  }

}

export const deliveryService = new DeliveryService(fetchRestService);
