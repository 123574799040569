import React, { useContext } from "react";

import { Outlet , Navigate } from "react-router";
import { CurrentUserContext } from "../CurrentUserContext/CurrentUserContext";

interface RequireAuthenticationProps  {
  redirectTo: string
}

// There are some pages that can only be accessed by authenticated users.
// If someone is not logged in, send them to the provided path
export const RequireAuthentication = ({ redirectTo }: RequireAuthenticationProps) => {
  const { currentUser } = useContext(CurrentUserContext);
  return !!currentUser ? <Outlet /> : <Navigate to={redirectTo} />;
}
