class FoxtrotError {}

class FoxtrotNetworkError extends FoxtrotError {
  public statusCode?: number;
  public code?: number;
  public message?: string;
  public errorsDetails?: any;

  constructor(statusCode?: number, code?: number, message?: string, errorsDetails?: any) {
    super();
    this.statusCode = statusCode;
    this.code = code;
    this.message = message;
    this.errorsDetails = errorsDetails;
  }
}

export { FoxtrotNetworkError };
