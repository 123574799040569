import { useContext, useEffect } from "react";
import { CurrentUserContext } from "../main/CurrentUserContext/CurrentUserContext";
import {
  getAnalyticsPageName,
  LOGIN,
  LOG_OUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SETUP_PASSWORD,
  ACCESS_DENIED
} from "../../common/helpers/foxtrot_page_name";
import { useLocation } from "react-router-dom";

const BLACKLISTED_PAGES = new Set([
  LOGIN,
  LOG_OUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SETUP_PASSWORD,
  ACCESS_DENIED
]);

export const identifyUserToAppcues = (analyticsPageName: string, userId: string, currentDCId: string, organizationId: string, roles: [string]) => {
  // Based on Java's string hash function
  // From https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/

  const Appcues = (window as any).Appcues;

  if (!Appcues) {
    return;
  }

  const hashUserId = (userId: string) => {
    const userIdString = String(userId);
    let hash = 0,
      i,
      chr;

    for (i = 0; i < userIdString.length; i++) {
      chr = userIdString.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash;
  };

  //TODO These currently aren't working properly, disabling till we can look into fixing them
  //App Cues are the little tutorial chips at the bottom right of pages
  if (!BLACKLISTED_PAGES.has(analyticsPageName) && false) {
    Appcues.identify(hashUserId(userId), {
      currentDCId,
      organizationId,
      roles: roles.join(',')
    });
  }
}

const AppcuesIdentifier = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const location = useLocation();

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const analyticsPageName = getAnalyticsPageName(window.location.pathname, document.title) as string;

    identifyUserToAppcues(analyticsPageName, currentUser.getId(), currentUser.getDCExtId(), currentUser.getOrganizationExtId(), currentUser.getRoles())

  }, [window.location.pathname, currentUser]);

  return null;
};

const ConnectedAppcuesIdentifier = (AppcuesIdentifier);

export { ConnectedAppcuesIdentifier as AppcuesIdentifier };
