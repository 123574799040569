import React, { useState, useEffect } from "react";

interface LocalizeCallback {
  (): void;
}

interface Localizeish {
  loaded: boolean;
  on(event: string, cb: LocalizeCallback): void;
  off(event: string, cb: LocalizeCallback): void;
  translate(template: string, expressions: any): string;
}

const _getLocalize = () => {
  const Localize = (window as any).Localize;
  if (Localize) {
    return Localize as Localizeish;
  } else {
    return null;
  }
};

const templateConcat = (strings: TemplateStringsArray, ...expressions: any[]) => {
  return strings.reduce((prevValue, currentValue, index) => {
    const expressionValue = expressions[index];
    if (expressionValue) {
      return `${prevValue}${currentValue}${expressionValue}`;
    }
    return `${prevValue}${currentValue}`;
  }, "");
};

const localize = (strings: TemplateStringsArray, ...expressions: any[]): string => {
  const Localize = _getLocalize();
  if (Localize && Localize.loaded) {
    const indexedExpressions: any = {};
    const template = strings.reduce((prevValue, currentValue, index) => {
      const expressionValue = expressions[index];
      if (expressionValue) {
        indexedExpressions[`value-${index}`] = expressionValue;
        return `${prevValue}${currentValue}%{value-${index}}`;
      }
      return `${prevValue}${currentValue}`;
    }, "");
    return Localize.translate(template, indexedExpressions);
  } else {
    return templateConcat(strings, expressions);
  }
};

export type Localizer = typeof localize;

interface LocalizeListenerProps {
  children(value: Localizer): React.ReactNode;
}

interface LocalizeListenerState {
  localize: Localizer;
}

class LocalizeListener extends React.Component<LocalizeListenerProps, LocalizeListenerState> {
  constructor(props: LocalizeListenerProps) {
    super(props);

    this.state = { localize };
  }

  _onLangChange = () => {
    this.setState({ localize });
  };

  componentDidMount = () => {
    const Localize = _getLocalize();
    if (Localize && Localize.loaded) {
      Localize.on("setLanguage", this._onLangChange);
    }
  };

  componentWillUnmount = () => {
    const Localize = _getLocalize();
    if (Localize && Localize.loaded) {
      Localize.off("setLanguage", this._onLangChange);
    }
  };

  render = () => {
    return this.props.children(this.state.localize);
  };
}

// HOC that adds a `localize` function prop.
// The prop will be updated whenever the language changes, so the component will automatically be re-rendered.
//
// Examples:
//
// Calling the HOC directly:
//
// const MyComponent = ({name, localize}) => (
//   <div>{localize`hello my name is ${name}`}</div>
// );
// const LocalizedComponent = Localize(MyComponent).
//
// Or as a Decorator:
//
// @Localize
// class MyClassComponent extends React.Component {
//   render = () => {
//     // The `localize` function will magically be available here as a prop.
//     return <div>{localize`hello my name is ${name}`}</div>
//   }
// }
const Localize = (Component: React.ComponentClass) => {
  return (props: any) => {
    return <LocalizeListener>{localize => <Component localize={localize} {...props} />}</LocalizeListener>;
  };
};

export interface WithLocalize {
  localize: Localizer;
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Diff<T, K> = Omit<T, keyof K>;

const withLocalize = <P extends Object>(WrappedComponent: React.ComponentType<P>) => {
  return class withLocalize extends React.Component<Diff<P, WithLocalize>> {
    render() {
      return <LocalizeListener>{localize => <WrappedComponent localize={localize} {...this.props as P} />}</LocalizeListener>
    }
  }
};

const useLocalize = () => {
  const [localizer, setLocalizer] = useState<Localizer>(() => localize);

  const updateLocalizer = () => {
    setLocalizer(() => localize);
  };

  useEffect(() => {
    const Localize = _getLocalize();
    if (Localize && Localize.loaded) {
      Localize.on("setLanguage", updateLocalizer);
    }

    return () => {
      if (Localize && Localize.loaded) {
        Localize.off("setLanguage", updateLocalizer);
      }
    };
  });

  return localizer;
};

export { Localize, localize, withLocalize, templateConcat, LocalizeListener, useLocalize };
