const SERVER_PUSH_NOTIFICATION_EVENT = {
  ROUTE_DELETED: "ROUTE_DELETED",
  ROUTE_CHANGED: "ROUTE_CHANGED",
  ROUTE_STATE_CHANGED: "ROUTE_STATE_CHANGED",
  ROUTE_ETA_CHANGED: "ROUTE_ETA_CHANGED",
  UNROUTABLE_ROUTE: "UNROUTABLE_ROUTE",
  ROUTE_FINISHED: "ROUTE_FINISHED",
  ROUTE_UNFINISHED: "ROUTE_UNFINISHED",
  ROUTE_ACTIVATED: "ROUTE_ACTIVATED",
  ROUTE_DEACTIVATED: "ROUTE_DEACTIVATED",
  DRIVER_ROUTE_ASSIGNMENT_CHANGED: "DRIVER_ROUTE_ASSIGNMENT_CHANGED",
  DRIVER_ROUTE_UNASSIGNED: "DRIVER_ROUTE_UNASSIGNED",
  DRIVER_LOCATION_CHANGED: "DRIVER_LOCATION_CHANGED",
  DRIVER_STATUS_CHANGED: "DRIVER_STATUS_CHANGED",
  DRIVER_DELIVERY_STATUS_CHANGED: "DRIVER_DELIVERY_STATUS_CHANGED",
  WAYPOINT_LOCATION_CHANGED: "WAYPOINT_LOCATION_CHANGED",
  LAST_ATTEMPT_FOR_DRIVER: "LAST_ATTEMPT_FOR_DRIVER",
};

// Prefixing with a namespace so we don't collide with other events
const PUSH_NOTIFICATION_EVENT = {
  ROUTE_CHANGED: "ws.ROUTE_CHANGED",
  ROUTE_STATE_CHANGED: "ws.ROUTE_STATE_CHANGED",
  ROUTE_ETA_CHANGED: "ws.ROUTE_ETA_CHANGED",
  UNROUTABLE_ROUTE: "ws.UNROUTABLE_ROUTE",
  ROUTE_FINISHED: "ws.ROUTE_FINISHED",
  ROUTE_UNFINISHED: "ws.ROUTE_UNFINISHED",
  DRIVER_ROUTE_ASSIGNMENT_CHANGED: "ws.DRIVER_ROUTE_ASSIGNMENT_CHANGED",
  DRIVER_ROUTE_UNASSIGNED: "ws.DRIVER_ROUTE_UNASSIGNED",
  DRIVER_LOCATION_CHANGED: "ws.DRIVER_LOCATION_CHANGED",
  DRIVER_STATUS_CHANGED: "ws.DRIVER_STATUS_CHANGED",
  DRIVER_DELIVERY_STATUS_CHANGED: "ws.DRIVER_DELIVERY_STATUS_CHANGED",
  WAYPOINT_LOCATION_CHANGED: "ws.WAYPOINT_LOCATION_CHANGED",
  LAST_ATTEMPT_FOR_DRIVER: "ws.LAST_ATTEMPT_FOR_DRIVER",
};

export {
  SERVER_PUSH_NOTIFICATION_EVENT, PUSH_NOTIFICATION_EVENT
}
