import io from "socket.io-client";
import JSONbig from 'json-bigint';
import {convertBigNumberToStringInObject} from "../../../common/helpers/collections";

import {
  SERVER_PUSH_NOTIFICATION_EVENT,
  PUSH_NOTIFICATION_EVENT
  // @ts-ignore
} from "../../../event/constants/push_notification_event";
import { CurrentUser } from "../../../common/models/current_user";
import { ApplicationType } from "../ApplicationTypeContext/ApplicationTypeContext";
import { userService } from "../../../rest/services/user_service";
import _ from "lodash";

export interface SubscriptionListener {
  (event: PUSH_NOTIFICATION_EVENT, ...args: any[]): void;
}

export interface Subscription {
  unsubscribe(): void;
}

class PushNotificationManager {
  private socket?: SocketIOClient.Socket;
  private clientFingerprint?: string;
  private currentConnectedDCId?: string;
  private errorConnecting: boolean = false;
  private subscriptionsByType: Map<PUSH_NOTIFICATION_EVENT, Map<string, SubscriptionListener>> = new Map();

  constructor(
    private webSocketHost: string,
    private applicationType: ApplicationType,
    private currentUser?: CurrentUser
  ) {
    // Connection logic largely moved from EventEmitterCtrl.js

    if (!this.currentUser) {
      return;
    }

    const options = {
      transportOptions: {
        polling: {
          extraHeaders: {
            "Foxtrot-Webapp-Type": ApplicationType
          }
        }
      },
      cookie: true,
      withCredentials: true
    };
    this.socket = io.connect(webSocketHost, options);
    this._registerEventListeners(this.currentUser, this.socket);
  }

  subscribe(event: PUSH_NOTIFICATION_EVENT, subscriptionListener: SubscriptionListener): Subscription {
    const subscriptionId = _.uniqueId();

    const subscription = {
      unsubscribe: () => {
        this.subscriptionsByType.get(event)!.delete(subscriptionId);
      }
    };

    const currentSubscriptions = this.subscriptionsByType.get(event) || new Map();
    currentSubscriptions.set(subscriptionId, subscriptionListener);
    this.subscriptionsByType.set(event, currentSubscriptions);

    return subscription;
  }

  _registerEventListeners = (currentUser: CurrentUser, socket: SocketIOClient.Socket) => {
    socket.on("connect", () => {
      this.errorConnecting = false;
      const currentDCId = currentUser.getDCId();
      this.currentConnectedDCId = currentDCId;
      socket.emit("subscribe_events_for_dc", { dc_id: currentDCId });
      userService.getClientFingerprint().then(clientFingerprint => {
        this.clientFingerprint = clientFingerprint;
      });
    });

    socket.on("error", () => {
      this.errorConnecting = true;
    });

    socket.on("connect_error", () => {
      this.errorConnecting = true;
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.ROUTE_DELETED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.ROUTE_DELETED,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.ROUTE_CHANGED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.ROUTE_CHANGED,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.ROUTE_ETA_CHANGED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.ROUTE_ETA_CHANGED,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.UNROUTABLE_ROUTE, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.UNROUTABLE_ROUTE,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.ROUTE_FINISHED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.ROUTE_FINISHED,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.ROUTE_UNFINISHED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.ROUTE_UNFINISHED,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.DRIVER_LOCATION_CHANGED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.DRIVER_LOCATION_CHANGED,
        parsedMessage,
        parsedMessage.driver_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.DRIVER_STATUS_CHANGED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.DRIVER_STATUS_CHANGED,
        parsedMessage,
        parsedMessage.driver_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.DRIVER_ROUTE_ASSIGNMENT_CHANGED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.DRIVER_ROUTE_ASSIGNMENT_CHANGED,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.DRIVER_ROUTE_UNASSIGNED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.DRIVER_ROUTE_UNASSIGNED,
        parsedMessage,
        parsedMessage.fox_map_id,
        parsedMessage.route_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.DRIVER_DELIVERY_STATUS_CHANGED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.DRIVER_DELIVERY_STATUS_CHANGED,
        parsedMessage,
        parsedMessage.route_id,
        parsedMessage.waypoint_ids
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.WAYPOINT_LOCATION_CHANGED, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.WAYPOINT_LOCATION_CHANGED,
        parsedMessage,
        parsedMessage.route_id,
        parsedMessage.waypoint_id
      );
    });

    socket.on(SERVER_PUSH_NOTIFICATION_EVENT.LAST_ATTEMPT_FOR_DRIVER, (message: string) => {
      const parsedMessage: any = convertBigNumberToStringInObject(JSONbig.parse(message));
      return this._broadcastPushNotification(
        PUSH_NOTIFICATION_EVENT.LAST_ATTEMPT_FOR_DRIVER,
        parsedMessage,
        parsedMessage.route_id,
        parsedMessage.driver_id
      );
    });
  };

  _broadcastPushNotification = (event: PUSH_NOTIFICATION_EVENT, message: any, ...args: any[]) => {
    if (message.sender === this.clientFingerprint) {
      return;
    }

    // Notify all of the eventListeners of the new notifications!
    const currentSubscriptions = this.subscriptionsByType.get(event) || new Map<string, SubscriptionListener>();
    Array.from(currentSubscriptions.values()).forEach(listener => listener(event, ...args));
  };
}

export { PushNotificationManager };
