import { Dispatch, SetStateAction, useState, useCallback } from "react";

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] => {
  const [state, setState] = useState<T | undefined>(() => {
    try {
      const localStorageValue = localStorage.getItem(key);
      if (localStorageValue !== null) {
        return JSON.parse(localStorageValue);
      } else {
        return initialValue;
      }
    } catch {
      // LocalStorage throws in private mode, or on access-restricted devices.
      return initialValue;
    }
  });

  const update: Dispatch<SetStateAction<T | undefined>> = useCallback(
    newValue => {
      if (newValue === undefined) {
        return;
      }
      try {
        localStorage.setItem(key, JSON.stringify(newValue));
        setState(newValue);
      } catch {
        // LocalStorage throws in private mode, or on access-restricted devices.
      }
    },
    [key, setState]
  );

  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch {
      // LocalStorage throws in private mode, or on access-restricted devices.
    }
  }, [key, setState]);

  return [state, update, remove];
};

export default useLocalStorage;
