import _ from "lodash";
import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { createTheme, ThemeProvider } from "@mui/material";

import Konami from "react-konami-code";

import { materialStyles } from "../config/material_styles";
import { materialStyles as newMaterialStyles } from "../config/MaterialStyles";
import { userService } from "../../rest/services/user_service";
import { GoogleAnalyticsTracker } from "../config/GoogleAnalyticsTracker";
import { AppcuesIdentifier } from "../config/AppcuesIdentifier";
import { FoxtrotBugsnag } from "../config/FoxtrotBugsnag";
import { CurrentUserContext } from "./CurrentUserContext/CurrentUserContext";
import { useCancelation } from "../../common/helpers/react/useCancelation";
import { CurrentUser } from "../../common/models/current_user";
import { DefaultPage } from "../../pages/DefaultPage/DefaultPage";
import { ApplicationType, ApplicationTypeContext } from "./ApplicationTypeContext/ApplicationTypeContext";
import { InternalRoutes } from "./routes/InternalRoutes";
import { ExternalRoutes } from "./routes/ExternalRoutes";
import { AuthenticationRoutes } from "./routes/AuthenticationRoutes";
import { PushNotificationManager } from "./PushNotificationManagerContext/PushNotificationManager";
import { PushNotificationManagerContext } from "./PushNotificationManagerContext/PushNotificationManagerContext";
import { AlertsBadgeCountManager } from "./AlertsBadgeCountContext/AlertsBadgeCountManager";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import { RequireAuthentication } from "./routes/RequireAuthentication";

import lazyWithRetry from "../../components/LazyWithRetry";

import "../../static/css/main.less"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react18-json-view/src/style.css'

// We'll continue using our material configuration, but the angular app
// sets the html font size to 12, whereas the "standard" is 16px.
// Let's override this value.
const reactMaterialStyles = { ...materialStyles };
reactMaterialStyles.typography = {
  ...materialStyles.typography,
  htmlFontSize: 16,
};

const NotFoundPage = lazyWithRetry(() => import("../../pages/NotFoundPage/NotFoundPage"));


interface FoxtrotConfig {
  appVersion: string;
  releaseStage: string;
  internal: boolean;
  webSocketHost: string;
}

const getDCIdFromPath = () => {
  const path = window.location.pathname;
  if (path.startsWith("/app/") && !path.startsWith("/app/internal")) {
    const results = path.match(/\/app\/([0-9]+).*/);
    if (results && results.length > 1) {
      return results[1]; // Return the id from the capturing group
    }
  }
};

const loadCurrentUser = async () => {
  try {
    const rawCurrentUser = await userService.getSelf();
    const currentUser = new CurrentUser();
    currentUser.setUserSeed(rawCurrentUser);
    const dcId = getDCIdFromPath();
    if (
      dcId &&
      _.includes(
        currentUser.getAllDCAccess().map(dcAccess => dcAccess.dc_id),
        dcId
      )
    ) {
      currentUser._setCurrentDC(dcId);
    } else {
      currentUser.setUserSeedAndDefaultDC(rawCurrentUser);
    }
    return currentUser;
  } catch (e) {
    if (e?.statusCode === 403) {
      return undefined;
    } else {
      throw e;
    }
  }
};

enum AppLoadingState {
  Loading,
  Error,
  Success
}



const App = () => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>(undefined);
  const [loadingState, setLoadingState] = useState(AppLoadingState.Loading);
  const [themeOverride, setThemeOverride] = useLocalStorage("new-theme-override", false);
  const cancelation = useCancelation();
  
  useEffect(() => {
    (async () => {
      try {
        setCurrentUser(await cancelation(loadCurrentUser()));
        setLoadingState(AppLoadingState.Success);
      } catch (e) {
        setLoadingState(AppLoadingState.Error);
      }
    })();
  }, [cancelation]);

  useEffect(() => {
    if(currentUser?.getDCId()) {
      // @ts-ignore
      window.__FOXTROT.currentDcId = currentUser.getDCId()
    }
  }, [currentUser?.getDCId()])

  if (loadingState !== AppLoadingState.Success) {
    // We decided that loading the user seed is the same as loading the page.
    // If it doesn't load, it's just like the page not loading, so the user will be expected to refresh.
    return <div />;
  }

  const foxtrotConfig = (window as any).__FOXTROT as FoxtrotConfig;
  const applicationType = foxtrotConfig.internal ? ApplicationType.Internal : ApplicationType.External;

  if (applicationType === ApplicationType.External) {
    // For the moment, we're gonna stick with a completely white background on external until we transition
    //reactMaterialStyles.palette!.background = { default: "#ffffff", paper: "#ffffff" };
  }
  const theme = createTheme(reactMaterialStyles);
  const newTheme = createTheme(newMaterialStyles);

  const bugsnagConfig = {
    apiKey: "0f1f47b9ba3733e8e087b21ad63beaa5",
    releaseStage: foxtrotConfig.releaseStage,
    appVersion: foxtrotConfig.appVersion
  };

  const pushNotificationManager = new PushNotificationManager(
    foxtrotConfig.webSocketHost,
    applicationType,
    currentUser
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FoxtrotBugsnag config={bugsnagConfig}>
        <Konami action={() => setThemeOverride(!themeOverride)} />
        <ThemeProvider theme={foxtrotConfig.internal || themeOverride ? newTheme : theme}>
          <ApplicationTypeContext.Provider value={{ applicationType }}>
            <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
              <PushNotificationManagerContext.Provider value={{ pushNotificationManager }}>
                <AlertsBadgeCountManager pushNotificationManager={pushNotificationManager} currentUser={currentUser}>
                  <BrowserRouter>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <GoogleAnalyticsTracker />
                      <AppcuesIdentifier />
                      <Suspense fallback={<div />}>
                        <Routes>
                          {["/", "/app", "/default", "/app/default", "/app/:dcId/default"].map(path => (
                            <Route key={path} path={path} element={<DefaultPage />} />
                          ))}
                          <Route path="/app" element={<RequireAuthentication redirectTo="/login" />}>
                            {foxtrotConfig.internal ? InternalRoutes : ExternalRoutes}
                          </Route>
                          {AuthenticationRoutes}
                          <Route path="/*" element={<NotFoundPage />} />
                        </Routes>
                      </Suspense>
                    </QueryParamProvider>
                  </BrowserRouter>
                </AlertsBadgeCountManager>
              </PushNotificationManagerContext.Provider>
            </CurrentUserContext.Provider>
          </ApplicationTypeContext.Provider>
        </ThemeProvider>
      </FoxtrotBugsnag>
    </LocalizationProvider>
  );
};

export { App };



