enum UserRole {
  // There's some duplication in user.js. Please make sure you update both.
  User = "USER",
  FoxtrotEngineering = "FOXTROT_ENGINEERING",
  Foxtrot = "FOXTROT",

  AnalyticsFileDownloads = "ANALYTICS_FILE_DOWNLOADS",
  DigitalCustomerManager = "CUSTOMERS_MANAGER",
  DriverPerformanceDashboard = "DRIVER_PERFORMANCE_DASHBOARD",
  FleetPerformanceDashboard = "FLEET_PERFORMANCE_DASHBOARD",
  DCAdmin = "DC_ADMIN",
  ImageViewer = "IMAGE_VIEWER",
  RealtimeDashboard = "REALTIME_DASHBOARD",
  RouteAnalyzer = "ROUTE_ANALYZER",
  RouteList = "ROUTE_LIST",
  RouteViewer = "ROUTE_VIEWER",
  RouteImporter = "ROUTE_IMPORTER",
  Alerts = "ALERTS",
  Merchandiser = "MERCHANDISER",
  CustomerRatingAnalyst = "CUSTOMER_RATING_ANALYST"
}

export { UserRole };
