import React, { useContext } from "react";

import { Navigate } from "react-router";
import { CurrentUserContext } from "../CurrentUserContext/CurrentUserContext";
import { PushNotificationManagerContext } from "../PushNotificationManagerContext/PushNotificationManagerContext";
import { UserRole } from "../../../common/models/UserRole";
import { WithRouter } from "./WithRouter";


interface AuthorizedRouteProps {
  component: React.ComponentType<any>;
  requiredRoles?: UserRole[];
}

// There are some pages that can only be accessed by authorized users.
// If someone doesn't have the appropriate permissions to access the page, send them to their default page.
export const AuthorizedRoute = ({ requiredRoles, component: Component }: AuthorizedRouteProps) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { pushNotificationManager } = useContext(PushNotificationManagerContext);

  if (!currentUser) {
    return <Navigate to="/login" />;
  } else if (requiredRoles !== undefined && !currentUser.hasAnyPermission(requiredRoles)) {
    return <Navigate to="/default" />;
  } else {
    return (
      <WithRouter render={(location, navigate, params, match) => (
        <Component currentUser={currentUser} navigate={navigate} location={location} match={match} params={params} pushNotificationManager={pushNotificationManager} />
      )} />
    );
  }
};
