import React from "react";

interface AlertsBadgeCountContextTypes {
  alertsBadgeCount: number;
}

const AlertsBadgeCountContext = React.createContext<AlertsBadgeCountContextTypes>({
  alertsBadgeCount: 0
});

export { AlertsBadgeCountContext };
