import React from "react";
import { PushNotificationManager, Subscription } from "../PushNotificationManagerContext/PushNotificationManager";
// @ts-ignore
import { PUSH_NOTIFICATION_EVENT } from "../../../event/constants/push_notification_event";
import { AlertsBadgeCountContext } from "./AlertsBadgeCountContext";
import { deliveryService } from "../../../rest/services/delivery_service";
import { FoxtrotNetworkError } from "../../../common/errors";
import { CurrentUser } from "../../../common/models/current_user";
import _ from "lodash";
import { UserRole } from "../../../common/models/UserRole";
import { LocalizeListener, WithLocalize } from "../../../common/helpers/localize";

interface AlertsBadgeCountManagerProps extends WithLocalize {
  children: React.ReactNode;
  pushNotificationManager: PushNotificationManager;
  currentUser?: CurrentUser;
}

interface AlertsBadgeCountManagerState {
  alertsBadgeCount: number;
  hasRefreshedBadgeCount: boolean;
}

class AlertsBadgeCountManager extends React.Component<AlertsBadgeCountManagerProps, AlertsBadgeCountManagerState> {
  state: AlertsBadgeCountManagerState = {
    alertsBadgeCount: 0,
    hasRefreshedBadgeCount: false
  };

  subscription?: Subscription = undefined;

  componentDidMount = async () => {
    if (this.props.currentUser && this.props.currentUser.hasPermission(UserRole.Alerts)) {
      this.subscription = this._subscribe();
      this._debouncedRefreshBadgeCount();
    }
  };

  _refreshBadgeCount = async () => {
    try {
      const newAlertsBadgeCount = await deliveryService.getPendingManagementActionCount(
        this.props.currentUser!.getDCId()
      );
      this.setState(({ alertsBadgeCount, hasRefreshedBadgeCount }) => {
        if (hasRefreshedBadgeCount && newAlertsBadgeCount > alertsBadgeCount) {
          this._sendNotificationIfEnabled(newAlertsBadgeCount);
        }
        return {
          hasRefreshedBadgeCount: true,
          alertsBadgeCount: newAlertsBadgeCount
        };
      });
    } catch (e) {
      if (!(e instanceof FoxtrotNetworkError)) {
        throw e;
      }
    }
  };

  _sendNotificationIfEnabled = (newCount: number) => {
    if ("Notification" in window && Notification.permission === "granted") {
      const title =
        newCount === 1
          ? this.props.localize`1 Delivery Needs Action`
          : this.props.localize`${newCount} Deliveries Need Action`;
      const notificationOptions = {
        body: this.props.localize`Click here to manage`,
        requireInteraction: true,
        icon: "../../../../static/img/foxtrot-logo-xs.png",
        tag: "notification"
      };
      const notification = new Notification(title, notificationOptions);
      notification.onclick = () => {
        window.open(`/app/${this.props.currentUser!.getDCId()}/alerts/list`);
      };
    }
  };

  _debouncedRefreshBadgeCount = _.debounce(this._refreshBadgeCount, 10 * 1000, {
    maxWait: 10 * 1000, // If there's a constant stream of calls, do it at least every 10 seconds
    leading: true, // Call it immediately
    trailing: true // If it was called within the cooldown interval, call it again so we don't miss data
  });

  render = () => (
    <AlertsBadgeCountContext.Provider value={{ alertsBadgeCount: this.state.alertsBadgeCount }}>
      {this.props.children}
    </AlertsBadgeCountContext.Provider>
  );

  componentWillUnmount = () => {
    this._unsubscribe();
  };

  _subscribe = () => {
    return this.props.pushNotificationManager.subscribe(PUSH_NOTIFICATION_EVENT.DRIVER_DELIVERY_STATUS_CHANGED, () => {
      this._debouncedRefreshBadgeCount();
    });
  };

  _unsubscribe = () => {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };
}

const LocalizedAlertsBadgeCountManager = (props: Omit<AlertsBadgeCountManagerProps, "localize">) => (
  <LocalizeListener>{localize => <AlertsBadgeCountManager localize={localize} {...props} />}</LocalizeListener>
);

export { LocalizedAlertsBadgeCountManager as AlertsBadgeCountManager };
