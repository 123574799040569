import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { CurrentUserContext } from "../../app/main/CurrentUserContext/CurrentUserContext";
import { ApplicationType, ApplicationTypeContext } from "../../app/main/ApplicationTypeContext/ApplicationTypeContext";
import { UserRole } from "../../common/models/UserRole";

const DefaultPage = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { applicationType } = useContext(ApplicationTypeContext);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const dcId = currentUser.getDCId();

  switch (applicationType) {
    case ApplicationType.External:
      if (currentUser.hasPermission(UserRole.DriverPerformanceDashboard)) {
        return <Navigate to={`/app/${dcId}/driver-performance`} />;
      } else if (currentUser.hasPermission(UserRole.RouteList)) {
        return <Navigate to={`/app/${dcId.toString()}/routes`} />;
      } else if (currentUser.hasPermission(UserRole.RouteAnalyzer)) {
        return <Navigate to={`/app/${dcId.toString()}/routes`} />;
      } else if (currentUser.hasPermission(UserRole.ImageViewer)) {
        return <Navigate to={`/app/${dcId.toString()}/route-images`} />;
      } else if (currentUser.hasPermission(UserRole.FleetPerformanceDashboard)) {
        return <Navigate to={`/app/${dcId}/fleet-performance`} />;
      } else if (currentUser.hasPermission(UserRole.DigitalCustomerManager)) {
        return <Navigate to={`/app/${dcId}/digital-customer-manager`} />;
      } else if (currentUser.hasPermission(UserRole.DCAdmin)) {
        return <Navigate to={`/app/${dcId}/settings/drivers`} />;
      } else {
        return <Navigate to="/not-found" />;
      }

    case ApplicationType.Internal:
      if (currentUser.hasPermission(UserRole.Foxtrot)) {
        return <Navigate to="/app/internal/account-manager" />;
      } else {
        return <Navigate to="/not-found" />;
      }
  }
};

export { DefaultPage };
