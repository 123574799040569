import React from "react";

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import { IConfig } from "@bugsnag/browser/dist/types/bugsnag-core/common";

interface FoxtrotBugsnagProps {
  config: IConfig;
  children: React.ReactNode;
}

const FoxtrotBugsnag = ({ config, children }: FoxtrotBugsnagProps) => {
  const bugsnagClient = bugsnag(config);
  bugsnagClient.use(bugsnagReact, React);
  const ErrorBoundary = bugsnagClient.getPlugin("react");

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export { FoxtrotBugsnag };
