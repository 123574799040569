import React, { useMemo } from "react";
import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
  
export const WithRouter = ({render}: {render: (location: any, navigate: any, params: any, match: any) => any}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const match = useMemo(() => ({params}), [params])

    return render(location, navigate, params, match);
}
  