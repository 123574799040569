import {flatten, isNil, isNumber, zip} from 'lodash';
import _ from "lodash";


export const _compare = (a, b) => {
  if (isNumber(a) && isNumber(b)) {
    return a - b;
  } else if (isNil(a) || isNil(b)) {
    return isNil(a) - isNil(b)
  } else {
    return a.toString().localeCompare(b.toString());
  }
};

const compareFunctionBy = (iteratee) => {
  // Given an iteratee returning one or more values, build a compareFunction that can be passed to Array.prototype.sort
  return (a, b, descending = false) => {
    return zip(flatten([iteratee(a)]), flatten([iteratee(b)]))
      .map(([a, b]) => (descending ? -1 : 1) * _compare(a, b))
      .reduce((acc, value) => (acc || value), 0);
  }
};

const flatGroupBy = (arr, byAttr) => {
  return arr.reduce((acc, item) => {
    const key = item[byAttr];
    if (key in acc) {
      throw new Error("List is not unique");
    }
    acc[key] = item;
    return acc
  }, {});
};

const keyBy = (arr, keyFn) => {
  return new Map(arr.map(i => [keyFn(i), i]));
};

const toSet = (arr) => {
  // TODO: Consider using ES5's Set object
  return arr.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
};

const _isBigFloatLike = (value) => {
  return !value.isInteger();
};

const convertBigNumberToStringInObject = (obj) => {
  if (_.isNil(obj)) {
    return obj;
  } else if (obj?._isBigNumber) {
    if (_isBigFloatLike(obj)) {
      return Number(obj.toString())
    } else {
      return obj.toString();
    }
  } else if (_.isPlainObject(obj)) {
    _.each(obj, (value, key) => {
      obj[key] = convertBigNumberToStringInObject(value);
    });
    return obj;
  } else if (_.isArray(obj)) {
    return _.map(obj, (value) => convertBigNumberToStringInObject(value));
  } else {
    return obj;
  }
};


export {
  compareFunctionBy,
  flatGroupBy,
  keyBy,
  toSet,
  convertBigNumberToStringInObject,
}
