import React from "react";

import { UnauthenticatedRoute } from "./UnauthenticatedRoute";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { Route, Routes } from "react-router-dom";

import { WithRouter } from "./WithRouter";

import lazyWithRetry from "../../../components/LazyWithRetry";

const ForgotPasswordPage = lazyWithRetry(() => import("../../../pages/ForgotPasswordPage/ForgotPasswordPage"));
const LoginPage = lazyWithRetry(() => import("../../../pages/LoginPage/LoginPage"));
const ResetPasswordPage = lazyWithRetry(() => import("../../../pages/ResetPasswordPage/ResetPasswordPage"));
const SetupPasswordPage = lazyWithRetry(() => import("../../../pages/SetupPasswordPage/SetupPasswordPage"));
const LogoutPage = lazyWithRetry(() => import("../../../pages/LogoutPage/LogoutPage"));


export const AuthenticationRoutes = (
  <>
    <Route path="/" element={<UnauthenticatedRoute />}>
      <Route path="login" element={<LoginPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="reset-password/:token" element={
        <WithRouter render={(location, navigate, params, match) => (
          <ResetPasswordPage params={params} />
        )} />
      } />
      <Route path="setup-password/:token" element={
        <WithRouter render={(location, navigate, params, match) => (
          <SetupPasswordPage params={params} />
        )} />
      } />
    </Route>
    <Route path="/logout" element={<AuthorizedRoute component={LogoutPage} />} />
  </>
);
