export const LOGIN = "Login";
export const LOG_OUT = "Log Out";
export const FORGOT_PASSWORD = "Forgot Password";
export const RESET_PASSWORD = "Reset Password";
export const SETUP_PASSWORD = "Setup Password";
export const ACCESS_DENIED = "Access Denied";
export const DEFAULT_REDIRECTION = "Default Redirection";
export const OVERVIEW = "Overview";
export const IMPORT_ROUTES = "Import Routes";
export const DIGITAL_CUSTOMER_MANAGER = "Digital Customer Manager";
export const ALL_ROUTES = "All Routes";
export const SETTINGS_DRIVERS = "Settings - Drivers";
export const SETTINGS_DRIVER_GROUPS = "Settings - Driver Groups";
export const SETTINGS_USERS = "Settings - Users";
export const SETTINGS_DC = "Settings - DC";
export const ROUTE_VIEWER = "Route Viewer";
export const INTERNAL_ROUTE_ANALYZER = "Internal Route Analyzer";
export const ROUTE_ANALYZER = "Route Analyzer";
export const ALERTS = "Alerts";
export const DRIVER_PERFORMANCE_DASHBOARD = "Driver Performance Dashboard";
export const FLEET_PERFORMANCE_DASHBOARD = "Fleet Performance Dashboard";
export const ROUTE_IMAGES = "Route Images";
export const FILE_DOWNLOADS = "File Downloads";
export const INTERNAL_ACCOUNT_MANAGER = "Internal Account Manager";
export const INTERNAL_COMPONENT_EXAMPLES = "Internal Component Examples";
export const INTERNAL_LOCATIONS_INSPECTOR = "Internal Locations Inspector";
export const INTERNAL_LOGIN_AS = "Internal Login-As";
export const INTERNAL_RUN_ROUTE_ANALYTICS = "Internal Run Route Analytics";
export const INTERNAL_RUN_ROUTE_STATE_ANALYZER = "Internal Run Route State Analyzer";
export const INTERNAL_SDK_DIAGNOSTIC_REQUEST = "Internal SDK Diagnostic Request";

function getAnalyticsPageName(href: String, title: String): String {
  if (href.endsWith("login")) {
    return LOGIN;
  }
  if (href.endsWith("logout")) {
    return LOG_OUT;
  }
  if (href.endsWith("forgot-password")) {
    return FORGOT_PASSWORD;
  }
  if (href.endsWith("reset-password")) {
    return RESET_PASSWORD;
  }
  if (href.endsWith("setup-password")) {
    return SETUP_PASSWORD;
  }
  if (href.endsWith("access_denied")) {
    return ACCESS_DENIED;
  }
  if (href.endsWith("default")) {
    return DEFAULT_REDIRECTION;
  }
  if (href.includes("realtime_dashboard")) {
    return OVERVIEW;
  }
  if (href.includes("map_importer")) {
    return IMPORT_ROUTES;
  }
  if (href.includes("digital-customer-manager")) {
    return DIGITAL_CUSTOMER_MANAGER;
  }
  if (href.endsWith("routes")) {
    return ALL_ROUTES;
  }
  if (href.includes("settings/drivers")) {
    return SETTINGS_DRIVERS;
  }
  if (href.includes("settings/driver_groups")) {
    return SETTINGS_DRIVER_GROUPS;
  }
  if (href.includes("settings/users")) {
    return SETTINGS_USERS;
  }
  if (href.includes("settings/dc")) {
    return SETTINGS_DC;
  }
  if (href.includes("routes")) {
    // Since it doesn't end with routes, it can only be the route viewer
    return ROUTE_VIEWER;
  }
  if (href.includes("internal/route-analyzer")) {
    return INTERNAL_ROUTE_ANALYZER;
  }
  if (href.includes("route-analyzer")) {
    return ROUTE_ANALYZER;
  }
  if (href.includes("alerts")) {
    return ALERTS;
  }
  if (href.includes("driver-performance")) {
    return DRIVER_PERFORMANCE_DASHBOARD;
  }
  if (href.includes("fleet-performance")) {
    return FLEET_PERFORMANCE_DASHBOARD;
  }
  if (href.includes("route-images")) {
    return ROUTE_IMAGES;
  }
  if (href.includes("file-downloads")) {
    return FILE_DOWNLOADS;
  }
  if (href.includes("internal/account-manager")) {
    return INTERNAL_ACCOUNT_MANAGER;
  }
  if (href.includes("internal/component-examples")) {
    return INTERNAL_COMPONENT_EXAMPLES;
  }
  if (href.includes("internal/locations")) {
    return INTERNAL_LOCATIONS_INSPECTOR;
  }
  if (href.includes("internal/login-as")) {
    return INTERNAL_LOGIN_AS;
  }
  if (href.includes("internal/run-route-analytics")) {
    return INTERNAL_RUN_ROUTE_ANALYTICS;
  }
  if (href.includes("internal/run-route-state-analyzer")) {
    return INTERNAL_RUN_ROUTE_STATE_ANALYZER;
  }
  if (href.includes("internal/sdk-diagnostic-request")) {
    return INTERNAL_SDK_DIAGNOSTIC_REQUEST;
  }
  return "Not Set: " + title + " - " + href;
}

export { getAnalyticsPageName };
