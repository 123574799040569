interface TrackPageViewParams {
  page_title: string;
}

export const trackPageView = ({ page_title }: TrackPageViewParams) => ({
  event_name: "page_view",
  event_params: { page_title }
});

export const trackRouteViewerStreetview = () => ({
  event_name: "FXT:CTA:routeviewer:StreetViewPressed",
  event_params: {
    category: "RouteViewer:CTA"
  }
});

export const trackRouteAnalyzerStreetview = () => ({
  event_name: "FXT:CTA:routeanalyzer:StreetViewPressed",
  event_params: {
    category: "RouteAnalyzer:CTA"
  }
});

interface DaysFromTodayParams {
  daysFromToday: number;
}

export const routeListDatePicked = ({ daysFromToday }: DaysFromTodayParams) => ({
  event_name: "FXT:DatePicker:RouteList:DatePicked",
  event_params: {
    category: "RouteList:DatePicker",
    value: daysFromToday
  }
});

export const liveOverviewDatePicked = ({ daysFromToday }: DaysFromTodayParams) => ({
  event_name: "FXT:DatePicker:LiveOverview:DatePicked",
  event_params: {
    category: "LiveOverview:DatePicker",
    value: daysFromToday
  }
});

export const driverPerformanceDashboardDatePicked = ({ daysFromToday }: DaysFromTodayParams) => ({
  event_name: "FXT:DatePicker:DriverPerfDash:DatePicked",
  event_params: {
    category: "RouteList:DatePicker",
    value: daysFromToday
  }
});

export const routeAnalyzerDatePicked = ({ daysFromToday }: DaysFromTodayParams) => ({
  event_name: "FXT:PageView:RouteAnalyzer:DatePicked",
  event_params: {
    category: "RouteAnalyzer:DatePicker",
    value: daysFromToday
  }
});

export const routeViewerDatePicked = ({ daysFromToday }: DaysFromTodayParams) => ({
  event_name: "FXT:PageView:RouteViewer:DatePicked",
  event_params: {
    category: "RouteViewer:DatePicker",
    value: daysFromToday
  }
});

interface Event {
  event_name: string;
  event_params: {
    [key: string]: string | number;
  };
}

export default (event: Event) => {
  const gtag = (window as any).gtag;
  if (gtag) {
    gtag("event", event.event_name, event.event_params);
  }
};
