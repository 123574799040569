import _ from 'lodash';
import moment from 'moment-timezone';

import { USER_PERMISSION } from "./user";

class DCAccess {

  constructor(dm) {
    const ignored = [];
    _.forOwn(dm, (v, k) => {
      if (!_.includes(ignored, k)) {
        this[k] = v;
      }
    });
  };

  getId() {
    return this.id.toString();
  }

  getDCId() {
    return this.dc_id.toString();
  };

  getDCName() {
    return this.dc_name;
  };

  getDCExtId() {
    return this.dc_ext_id;
  }

  isWebDefault() {
    return this.is_web_default;
  };

  getTimeZone() {
    return this.time_zone;
  };

  getUnitsSystems() {
    return this.units_systems;
  };

}

class CurrentUser {

  setUserSeed = (user) => {
    this._updateSettings(user);
    this.isLoaded = true;
  };

  setUserSeedAndDefaultDC = (user) => {
    this._updateSettings(user);
    this._setCurrentDC(this.getDefaultDcId());
  };

  _updateSettings = (newSettings) => {
    const ignored = [];
    _.forOwn(newSettings, (v, k) => {
      if (!_.includes(ignored, k)) {
        if (k === "all_dc_access") {
          v = _.map(v, (dm) => new DCAccess(dm))
        }
        this[k] = v;
      }
    });
  };

  getId = () => {
    return this.id;
  };

  getName = () => {
    return this.name;
  };

  getUsername = () => {
    return this.username;
  };

  useSIUnitsSystems = () => {
    return this.unitsSystems() === 'SI';
  };

  distanceUnits = () => {
    return this.useSIUnitsSystems() ? 'km' : 'mi';
  };

  fuelCostUnits = () => {
    return this.useSIUnitsSystems() ? '$/L' : '$/gal';
  };

  fuelEconomyUnits = () => {
    return this.useSIUnitsSystems() ? 'km/L' : 'mpg';
  };

  getCurrentTimeWithTZ = () => {
    return moment.tz(this.timeZone());
  };

  getDateTimeWithTZ = (epochTime) => {
    return moment.unix(epochTime).tz(this.timeZone());
  };

  getDateTimeWithTZAndFormat = (epochTime, format) => {
    return moment.unix(epochTime).format(format);
  };

  getRoles = () => {
    return this.roles;
  }

  convertMomentToUserTimezone = (momentDateTime) => {
    // This method ignores the timezone of the moment object and assume whatever time value is for the user's time zone.
    const naiveTimeString = momentDateTime.format('YYYY-MM-DD HH:mm');
    return moment.tz(naiveTimeString, this.timeZone());
  };

  hasPermission = (permission) => {
    const userRoles = this.roles;
    if (permission === USER_PERMISSION.FOXTROT_ENGINEERING) {
      // FOXTROT_ENGINEERING must be explicitly granted
      return userRoles.includes(USER_PERMISSION.FOXTROT_ENGINEERING);
    } else if (userRoles.includes(USER_PERMISSION.FOXTROT)) {
      // FOXTROT gives you every other permission
      return permission !== USER_PERMISSION.FOXTROT_ENGINEERING;
    } else {
      return _.includes(userRoles, permission);
    }
  };

  hasAnyPermission = (permissions) => {
    return permissions.some(permission => this.hasPermission(permission))
  };

  getOrganizationExtId = () => {
    return this.organization_ext_id;
  };

  getOrganizationName = () => {
    return this.organization_name;
  };

  getDefaultDcId = () => {
    const defaultDcAccess = _.first(_.filter(this.all_dc_access, (x) => x.is_web_default));
    if (_.isNil(defaultDcAccess)) {
      // Sort the list and pick the first one
      return _.first(_.sortBy(this.all_dc_access, (x) => x.getDCName())).getDCId();
    } else {
      return defaultDcAccess.getDCId();
    }
  };

  getAllDCAccess = () => {
    return this.all_dc_access;
  };

  canAccessDC = (dcId) => {
    const index = _.findIndex(this.all_dc_access, (da) => da.getDCId() === dcId.toString());
    return index !== -1;
  };

  /**
   * Should only be called during the initialization of the app
   */
  _setCurrentDC = (dcId) => {
    const da = _.find(this.all_dc_access, (da) => da.getDCId().toString() === dcId.toString());
    this.dc = {
      id: da.getDCId(),
      name: da.getDCName(),
      extId: da.getDCExtId(),
      timeZone: da.getTimeZone(da.getDCId()),
      unitsSystems: da.getUnitsSystems(),
    };
  };

  getDC = () => {
    return this.dc;
  };

  getDCId = () => {
    return this.getDC().id;
  };

  getAllDCIds = () => {
    return this.all_dc_access.map(da => da.getDCId().toString());
  };

  getDCExtId = () => {
    return this.getDC().extId;
  };

  getDCName = () => {
    return this.getDC().name;
  };

  /**
   * These setters are a bit nasty, but we need them in order to make the user experience for updating the settings
   * page better. We can just refresh the whole page after they finished updating the settings page...
   */
  setTimeZone = (timeZone) => {
    this.dc.timeZone = timeZone;
  };

  setUnitsSystems = (unitsSystems) => {
    this.dc.unitsSystems = unitsSystems;
  };

  unitsSystems = () => {
    // Please do not use me.
    return this.dc.unitsSystems;
  };

  timeZone = () => {
    // Please do not use me.
    return this.dc.timeZone;
  };

  getTimeZone = () => {
    // I can't deal with those old names any longer
    return this.timeZone();
  };

  getUnitSystem = () => {
    // I can't deal with those old names any longer
    return this.unitsSystems();
  };

  getSessionCreatedByUserId = () => {
    return this.session_created_by_user_id;
  };

};

export {
  DCAccess,
  CurrentUser
}
