import { useRef, useEffect } from "react";

interface CancelablePromise<T> {
  promise: Promise<T>;
  cancel(): void;
}

const makeCancelable = <T extends Object>(promise: Promise<T>): CancelablePromise<T> => {
  let isCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(val => (isCanceled ? () => {} : resolve(val))).catch(error => (isCanceled ? () => {} : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    }
  };
};

interface Canceler {
  <T>(promise: Promise<T>): Promise<T>;
}

const useCancelation = () => {
  const promises = useRef<CancelablePromise<any>[]>([]);

  // @ts-ignore
  const wrapper = useRef<Canceler>(<T>(promise: Promise<T>) => {
    // @ts-ignore
    const cPromise = makeCancelable(promise);
    promises.current.push(cPromise);
    return cPromise.promise;
  });

  useEffect(() => {
    return function cancel() {
      promises.current.forEach(p => p.cancel());
      promises.current = [];
    };
  }, []);

  return wrapper.current;
};

export { useCancelation };
