import axios from "axios";
import JSONbig from 'json-bigint';

import {FoxtrotNetworkError} from "../../common/errors";
import {convertBigNumberToStringInObject} from "../../common/helpers/collections";

import { CurrentUserContext } from "../../app/main/CurrentUserContext/CurrentUserContext";
import {useContext} from "react";


export class FoxtrotRestService {

  extractResponsePromise = () => {};

  mapErrorPromise = () => {};

  delete = (url, options) => {};

  get = (url, options) => {};

  patch = (url, params) => {};

  post = (url, params) => {};

  put = (url, params) => {};
}

const transformRequest = [data => JSONbig.stringify(data)];
const transformResponse = [data => JSONbig.parse(data), data => convertBigNumberToStringInObject(data)];

class FetchRestService extends FoxtrotRestService {

  extractResponsePromise = async (promise) => {
    try {
      const response = await promise;
      return response?.data?.data;
    } catch (e) {
      return this.mapErrorPromise(e);
    }
  };

  mapErrorPromise = (e) => {
    // This method is split up into two for historical reasons.
    // New code should never call this method directly.
    const response = e.response;
    if (response?.data?.data) {
      const {code, message, errors} = e.response.data.data;
      throw new FoxtrotNetworkError(response.status, code, message, errors);
    } else {
      console.error("Error During Response Promise", e);
      throw new FoxtrotNetworkError(null, null, 'Unable to connect.', null)
    }
  };

  _addDefaultHeaders = (headers) => {
    const amendedHeaders = {...headers};
    if (!amendedHeaders.hasOwnProperty('Content-Type')) {
      amendedHeaders['Content-Type'] = 'application/json';
    }
    if (!amendedHeaders.hasOwnProperty('Foxtrot-DC-ID')) {
      amendedHeaders['Foxtrot-DC-ID'] = window.__FOXTROT.currentDcId || '';
    }
    amendedHeaders["X-Foxtrot-Webapp"] = true;
    amendedHeaders["X-Foxtrot-WebID"] = window.__FOXTROT.webAppIdentifier || '';
    return amendedHeaders;
  };

  delete = (url, options = {}) => {
    const {headers, params, ...otherOptions} = options;
    const amendedHeaders = this._addDefaultHeaders(headers || {});
    return axios.delete(url, {transformRequest, transformResponse, data: params, headers: amendedHeaders, withCredentials: true, ...otherOptions});
  };

  get = (url, options = {}) => {
    const {headers, ...otherOptions} = options;
    const amendedHeaders = this._addDefaultHeaders(headers || {});
    return axios.get(url, {transformRequest, transformResponse, headers: amendedHeaders, withCredentials: true, ...otherOptions});
  };

  patch = (url, params, options = {}) => {
    const {headers, ...otherOptions} = options;
    const amendedHeaders = this._addDefaultHeaders(headers || {});
    return axios.patch(url, params, {transformRequest, transformResponse, headers: amendedHeaders, withCredentials: true, ...otherOptions});
  };

  post = (url, params, options = {}) => {
    const {headers, ...otherOptions} = options;
    const amendedHeaders = this._addDefaultHeaders(headers || {});
    return axios.post(url, params, {transformRequest, transformResponse, headers: amendedHeaders, withCredentials: true, ...otherOptions});
  };

  put = (url, params, options = {}) => {
    const {headers, ...otherOptions} = options;
    const amendedHeaders = this._addDefaultHeaders(headers || {});
    return axios.put(url, params, {transformRequest, transformResponse, headers: amendedHeaders, withCredentials: true, ...otherOptions});
  };

}

export const fetchRestService = new FetchRestService();