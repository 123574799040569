import React from "react";
import { Route } from "react-router-dom";

import { AuthorizedRoute } from "./AuthorizedRoute";
import { RequireAuthentication } from "./RequireAuthentication";
import { UserRole } from "../../../common/models/UserRole";
import lazyWithRetry from "../../../components/LazyWithRetry";


const AnalyticsFileDownloadsPage = lazyWithRetry(() =>
  import("../../../analytics_file_downloads/pages/analytics_file_downloads_page")
);
const DigitalCustomerManagerPage = lazyWithRetry(() => import("../../../digital_customer_manager/DigitalCustomerManagerPage"));
const DriverPerformancePage = lazyWithRetry(() => import("../../../driver_performance/pages/driver_performance_page"));
// @ts-ignore
const FleetPerformancePage = lazyWithRetry(() => import("../../../fleet_performance/pages/fleet_performance_page"));
// @ts-ignore
const RouteImagesPage = lazyWithRetry(() => import("../../../pages/RouteImagesPage/RouteImagesPage"));
const RouteListPage = lazyWithRetry(() => import("../../../pages/RouteListPage/RouteListPage"));
// @ts-ignore
const RouteViewerPage = lazyWithRetry(() => import("../../../pages/RouteViewerPage/RouteViewerPage"));
const MapImporter = lazyWithRetry(() => import("../../../pages/MapImporter/MapImporter"));
// @ts-ignore
const UserRouteAnalyzerPage = lazyWithRetry(() => import("../../../pages/UserRouteAnalyzerPage/UserRouteAnalyzerPage"));
// @ts-ignore
const DCSettingsPage = lazyWithRetry(() => import("../../../settings/dc/components/dc_settings_page"));
// @ts-ignore
const UsersSettingsPage = lazyWithRetry(() => import("../../../settings/users/components/users_page"));
// @ts-ignore
const DriversSettingsPage = lazyWithRetry(() => import("../../../settings/driver_profiles/components/driver_profiles_page"));
// @ts-ignore
const DriverGroupsSettingsPage = lazyWithRetry(() => import("../../../settings/driver_groups/components/driver_groups_page"));
// @ts-ignore
const AlertsPage = lazyWithRetry(() => import("../../../pages/AlertsPage/AlertsPage"));
// @ts-ignore
const RealtimeDashboard = lazyWithRetry(() => import("../../../realtime_dashboard/pages/realtime_dashboard_page"));

export const ExternalRoutes = (
  <Route element={<RequireAuthentication redirectTo="/login" />}>
    <Route
      path=":dcId/analytics-file-downloads"
      element={
        <AuthorizedRoute 
          component={AnalyticsFileDownloadsPage} 
          requiredRoles={[UserRole.AnalyticsFileDownloads]} />
      }
    />
    <Route
      path=":dcId/digital-customer-manager"
      element={
        <AuthorizedRoute 
          component={DigitalCustomerManagerPage} 
          requiredRoles={[UserRole.DigitalCustomerManager]} />
      }
    />
    <Route
      path=":dcId/driver-performance"
      element={
        <AuthorizedRoute 
          component={DriverPerformancePage} 
          requiredRoles={[UserRole.DriverPerformanceDashboard]} />
      }
    />
    <Route
      path=":dcId/fleet-performance"
      element={
        <AuthorizedRoute 
          component={FleetPerformancePage} 
          requiredRoles={[UserRole.FleetPerformanceDashboard]} />
      }
    />
    <Route
      path=":dcId/routes"
      element={
        <AuthorizedRoute 
          component={RouteListPage} 
          requiredRoles={[UserRole.RouteList, UserRole.RouteAnalyzer, UserRole.RouteViewer]} />
      }
    />
    <Route
      path=":dcId/routes/:routeId"
      element={
        <AuthorizedRoute 
          component={RouteViewerPage} 
          requiredRoles={[UserRole.RouteViewer]} />
      }
    />
    <Route
      path=":dcId/route-images"
      element={
        <AuthorizedRoute 
          component={RouteImagesPage} 
          requiredRoles={[UserRole.ImageViewer]} />
      }
    />
    <Route
      path=":dcId/settings/dc"
      element={
        <AuthorizedRoute 
          component={DCSettingsPage} 
          requiredRoles={[UserRole.DCAdmin]} />
      }
    />
    <Route
      path=":dcId/settings/users"
      element={
        <AuthorizedRoute 
          component={UsersSettingsPage} 
          requiredRoles={[UserRole.DCAdmin]} />
      }
    />
    <Route
      path=":dcId/settings/drivers"
      element={
        <AuthorizedRoute 
          component={DriversSettingsPage} 
          requiredRoles={[UserRole.DCAdmin]} />
      }
    />
    <Route
      path=":dcId/settings/driver_groups"
      element={
        <AuthorizedRoute 
          component={DriverGroupsSettingsPage} 
          requiredRoles={[UserRole.DCAdmin]} />
      }
    />
    <Route
      path=":dcId/route-analyzer/:routeId"
      element={
        <AuthorizedRoute 
          component={UserRouteAnalyzerPage} 
          requiredRoles={[UserRole.RouteAnalyzer]} />
      }
    />
    <Route
      path=":dcId/map_importer"
      element={
        <AuthorizedRoute 
          component={MapImporter} 
          requiredRoles={[UserRole.RouteImporter]} />
      }
    />
    <Route
      path=":dcId/alerts/list"
      element={
        <AuthorizedRoute 
          component={AlertsPage} 
          requiredRoles={[UserRole.Alerts]} />
      }
    />
    <Route
      path=":dcId/realtime_dashboard"
      element={
        <AuthorizedRoute 
          component={RealtimeDashboard} 
          requiredRoles={[UserRole.RealtimeDashboard]} />
      }
    />
  </Route>
);
