import React from "react";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { UserRole } from "../../../common/models/UserRole";
import { Route } from "react-router-dom";

import lazyWithRetry from "../../../components/LazyWithRetry";

const OrganizationListPage = lazyWithRetry(() => import("../../../pages/internal/account_manager/OrganizationListPage"));
const EditDCUserAccessPage = lazyWithRetry(() => import("../../../pages/internal/account_manager/EditUserDCAccessPage"));

const BulkCreateDriversPage = lazyWithRetry(() =>
  import("../../../pages/internal/account_manager/BulkCreateDriversPage/BulkCreateDriversPage")
);
const ComponentExamplesPage = lazyWithRetry(() => import("../../../pages/internal/ComponentExamplesPage/ComponentExamplesPage"));
const CustomerInspectorFinderPage = lazyWithRetry(() =>
  import("../../../pages/internal/CustomerInspectorFinderPage/CustomerInspectorFinderPage")
);
const CustomerInspectorResultsPage = lazyWithRetry(() =>
  import("../../../pages/internal/CustomerInspectorResultsPage/CustomerInspectorResultsPage")
);
// @ts-ignore
const UserListPage = lazyWithRetry(() => import("../../../pages/internal/account_manager/UserListPage/UserListPage"));
const DCDeliveryCodeEditorPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/account_manager/DCDeliveryCodeEditorPage/DCDeliveryCodeEditorPage")
);
const DCCreationPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/account_manager/DCCreationPage/DCCreationPage")
);
const OrganizationSettingsPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/account_manager/OrganizationSettingsPage/OrganizationSettingsPage")
);
const WarehouseEditorPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/account_manager/WarehouseEditorPage/WarehouseEditorPage")
);
const FinishRoutesPage = lazyWithRetry(() => import("../../../pages/internal/FinishRoutesPage/FinishRoutesPage"));
// @ts-ignore
const InviteUsersPage = lazyWithRetry(() => import("../../../pages/internal/InviteUsersPage/InviteUsersPage"));
const LocationLearningWeightEditorPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/account_manager/LocationLearningWeightEditorPage/LocationLearningWeightEditorPage")
);
const LoginAsPage = lazyWithRetry(() => import("../../../pages/internal/LoginAsPage/LoginAsPage"));
const RouteDebuggerPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/RouteDebuggerPage/RouteDebuggerPage")
);
const InternalRouteAnalyzerFinderPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/InternalRouteAnalyzerFinderPage/InternalRouteAnalyzerFinderPage")
);
const InternalRouteAnalyzerPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/InternalRouteAnalyzerPage/InternalRouteAnalyzerPage")
);
const RunRouteAnalyticsPage = lazyWithRetry(() => import("../../../pages/internal/RunRouteAnalyticsPage/RunRouteAnalyticsPage"));
const RunRouteStateAnalyzerPage = lazyWithRetry(() =>
  import("../../../pages/internal/RunRouteStateAnalyzerPage/RunRouteStateAnalyzerPage")
);
const SDKDiagnosticRequestPage = lazyWithRetry(() =>
  import("../../../pages/internal/SDKDiagnosticRequestPage/SDKDiagnosticRequestPage")
);
const SDKDiagnosticResponsePage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/SDKDiagnosticResponsePage/SDKDiagnosticResponsePage")
);

const DeleteDCPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/DeleteDCPage/DeleteDCPage")
);

const OrganizationEtaDiagnosticsPage = lazyWithRetry(() =>
  // @ts-ignore
  import("../../../pages/internal/OrganizationEtaDiagnosticsPage/OrganizationEtaDiagnosticsPage")
);

export const InternalRoutes = (
  <Route path="internal">
    <Route
      path="account-manager"
      element={<AuthorizedRoute component={OrganizationListPage} />}
    />
    <Route
      path="account-manager/organizations/:orgId"
      element={<AuthorizedRoute component={OrganizationSettingsPage} />}
    />
    <Route
      path="account-manager/organizations/:organizationId/edit-user-dc-access"
      element={<AuthorizedRoute component={EditDCUserAccessPage} />}
    />
    <Route
      path="account-manager/location-learning-weights/dc/:dcId"
      element={<AuthorizedRoute component={LocationLearningWeightEditorPage} />}
    />
    <Route
      path="account-manager/dc-delivery-codes/dc/:dcId"
      element={<AuthorizedRoute component={DCDeliveryCodeEditorPage} />}
    />
    <Route
      path="account-manager/create-dcs/:orgId"
      element={<AuthorizedRoute component={DCCreationPage} />}
    />
    <Route
      path="account-manager/warehouse-editor/dc/:dcId"
      element={<AuthorizedRoute component={WarehouseEditorPage} />}
    />
    <Route
      path="account-manager/users/:orgId/dc/:dcId"
      element={<AuthorizedRoute component={UserListPage} />}
    />
    <Route
      path="locations"
      element={<AuthorizedRoute component={CustomerInspectorFinderPage} />}
    />
    <Route
      path="locations/:dcFacilityId"
      element={<AuthorizedRoute component={CustomerInspectorResultsPage} />}
    />
    <Route
      path="finish-routes"
      element={<AuthorizedRoute component={FinishRoutesPage} />}
    />
    <Route
      path="invite-users/:dcId"
      element={<AuthorizedRoute component={InviteUsersPage} />}
    />
    <Route
      path="bulk-add-drivers/:dcId"
      element={<AuthorizedRoute component={BulkCreateDriversPage} />}
    />
    <Route
      path="login-as"
      element={<AuthorizedRoute component={LoginAsPage} />}
    />
    <Route
      path="route-analyzer"
      element={<AuthorizedRoute component={InternalRouteAnalyzerFinderPage} />}
    />
    <Route
      path="route-analyzer/:routeId"
      element={<AuthorizedRoute component={InternalRouteAnalyzerPage} />}
    />
    <Route
      path="route-debugger"
      element={<AuthorizedRoute component={RouteDebuggerPage} />}
    />
    <Route
      path="organization-eta-diagnostics"
      element={<AuthorizedRoute component={OrganizationEtaDiagnosticsPage} />}
    />
    <Route
      path="component-examples"
      element={
        <AuthorizedRoute 
          component={ComponentExamplesPage} 
          requiredRoles={[UserRole.FoxtrotEngineering]}
        />
      }
    />
    <Route
      path="run-route-analytics"
      element={
        <AuthorizedRoute 
          component={RunRouteAnalyticsPage} 
          requiredRoles={[UserRole.FoxtrotEngineering]}
        />
      }
    />
    <Route
      path="run-route-state-analyzer"
      element={
        <AuthorizedRoute 
          component={RunRouteStateAnalyzerPage} 
          requiredRoles={[UserRole.FoxtrotEngineering]}
        />
      }
    />
    <Route
      path="sdk-diagnostic-request"
      element={
        <AuthorizedRoute 
          component={SDKDiagnosticRequestPage}
        />
      }
    />
    <Route
      path="sdk-diagnostic-response"
      element={
        <AuthorizedRoute 
          component={SDKDiagnosticResponsePage}
          requiredRoles={[UserRole.FoxtrotEngineering]}
        />
      }
    />
    <Route
      path="delete-dc"
      element={
        <AuthorizedRoute 
          component={DeleteDCPage}
          requiredRoles={[UserRole.FoxtrotEngineering]}
        />
      }
    />
  </Route>
);
