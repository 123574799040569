import { useContext, useEffect } from "react";
import { CurrentUserContext } from "../main/CurrentUserContext/CurrentUserContext";
import { CurrentUser } from "../../common/models/current_user";
import { getAnalyticsPageName } from "../../common/helpers/foxtrot_page_name";
import trackEvent, { trackPageView } from "../config/analytics/events";
import { useLocation } from "react-router-dom";

const GoogleAnalyticsTracker = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const location = useLocation();

  const _trackPageVisit = (path: string, currentUser?: CurrentUser) => {
    if (currentUser !== undefined && currentUser !== null) {
      const gtag = (window as any).gtag;
      if (gtag) {
        const userSessionId = currentUser.getSessionCreatedByUserId();
        if (userSessionId !== undefined) {
          gtag("set", {
            user_id: userSessionId.toString()
          });
        }
        gtag("set", "user_properties", {
          user_id: userSessionId?.toString(),
          dc_id: currentUser.getDCId().toString(),
          org_ext_id: currentUser.getOrganizationExtId(),
          logged_in_as_user_id: currentUser.getId().toString()
        });

        trackEvent(trackPageView({ page_title: getAnalyticsPageName(path, document.title) as string }));
      }
    }
  };

  useEffect(() => {
    _trackPageVisit(window.location.pathname, currentUser);
  }, [window.location.pathname, currentUser]);

  return null;
};

const ConnectedGoogleAnalyticsTracker = (GoogleAnalyticsTracker);

export { ConnectedGoogleAnalyticsTracker as GoogleAnalyticsTracker };
