import React, { useContext } from "react";

import { Navigate, Outlet } from "react-router";
import { CurrentUserContext } from "../CurrentUserContext/CurrentUserContext";

// There are some pages that can only be accessed by unauthenticated users, like the login or signup page.
// If they're trying to go to these pages while signed in, send them to the default page instead.
export const UnauthenticatedRoute = () => {
  const { currentUser } = useContext(CurrentUserContext);
  return !!currentUser ? <Navigate to="/default" /> : <Outlet />;
};
