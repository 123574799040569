import { PaletteColor, PaletteColorOptions } from "@mui/material/styles/createPalette";

// @ts-ignore
import SimplonRegularWoff2 from "../fonts/SimplonRegular.woff2";

const simplon: any = {
  fontFamily: "Simplon",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Simplon'),
    local('Simplon-Regular'),
    url(${SimplonRegularWoff2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};

const midnightBlack = "#060526";
const teal = "#48e9c8";
const white = "#ffffff";
const gray = "#f4f4f4";
const lightTeal = "#eefdfa";
const jamRed = "#ff2e01";
const electricYellow = "#ffb706";
const navBlue = "#037ff6";
const mysteryPurple = "#7b00f2";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    brand: {
      midnightBlack: PaletteColor;
      teal: PaletteColor;
      white: PaletteColor;
      gray: PaletteColor;
      lightTeal: PaletteColor;
      jamRed: PaletteColor;
      electricYellow: PaletteColor;
      navBlue: PaletteColor;
      mysteryPurple: PaletteColor;
    };
    good: PaletteColor;
    bad: PaletteColor;
    neutral: PaletteColor;
    icon: PaletteColor;
    visit: {
      active: PaletteColor;
      unfinished: PaletteColor;
      finished: PaletteColor;
    };
    delivery: {
      notAttempted: PaletteColor;
      current: PaletteColor;
      success: PaletteColor;
      failed: PaletteColor;
      visitLater: PaletteColor;
      authorizeReattempt: PaletteColor;
      mixed: PaletteColor;
    };
  }

  interface PaletteOptions {
    brand: {
      midnightBlack: PaletteColorOptions;
      teal: PaletteColorOptions;
      white: PaletteColorOptions;
      gray: PaletteColorOptions;
      lightTeal: PaletteColorOptions;
      jamRed: PaletteColorOptions;
      electricYellow: PaletteColorOptions;
      navBlue: PaletteColorOptions;
      mysteryPurple: PaletteColorOptions;
    };
    good: PaletteColorOptions;
    bad: PaletteColorOptions;
    neutral: PaletteColorOptions;
    icon: PaletteColorOptions;
    visit: {
      active: PaletteColorOptions;
      finished: PaletteColorOptions;
      unfinished: PaletteColorOptions;
    };
    delivery: {
      notAttempted: PaletteColorOptions;
      current: PaletteColorOptions;
      success: PaletteColorOptions;
      failed: PaletteColorOptions;
      visitLater: PaletteColorOptions;
      authorizeReattempt: PaletteColorOptions;
      mixed: PaletteColorOptions;
    };
  }
}

const materialStyles = {
  palette: {
    brand: {
      midnightBlack: { main: midnightBlack, contrastText: white },
      teal: { main: teal, contrastText: midnightBlack },
      white: { main: white, contrastText: midnightBlack },
      gray: { main: gray, contrastText: midnightBlack },
      lightTeal: { main: lightTeal, contrastText: midnightBlack },
      jamRed: { main: jamRed, contrastText: white },
      electricYellow: { main: electricYellow, contrastText: white },
      navBlue: { main: navBlue, contrastText: white },
      mysteryPurple: { main: mysteryPurple, contrastText: white }
    },
    primary: {
      main: midnightBlack,
      contrastText: white
    },
    secondary: {
      main: teal,
      contrastText: midnightBlack
    },
    good: {
      light: "#80e27e",
      main: "#4caf50",
      dark: "#087f23",
      contrastText: "#fff"
    },
    bad: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000"
    },
    neutral: {
      light: "#e0e0e0",
      main: "#9e9e9e",
      dark: "#424242",
      contrastText: "#000"
    },
    icon: {
      main: "#0000008a"
    },
    visit: {
      active: { main: "#56E9C8" },
      finished: { main: "#060525" },
      unfinished: { main: "#adadad" }
    },
    delivery: {
      notAttempted: { main: "#adadad" },
      success: { main: "#00B155", light: "#cbffe4" },
      failed: { main: "#ED4957", light: "#fbd4d8" },
      visitLater: { main: "#F7AF00", light: "#ffdf91" },
      authorizeReattempt: { main: "#7F21FF", light: "#d7baff" },
      mixed: { main: "#718290" },
      current: { main: "#62ABF4", light: "#f1f8fe" }
    }
  },
  typography: {
    fontFamily: '"Simplon", "Helvetica", "Arial", sans-serif'
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [simplon]
      }
    }
  }
};

export { materialStyles };
